<template>
  <div class="w100p pl10 pr10">
    <div class="textc mb20">
      <p class="textc fs9  fwbold">优惠券模块</p>
      <p class="fs7">(图中所示商品为演示优惠券，请更改相关优惠券)</p>
    </div>
    <div class="">
      <p class="fs8 mb10">
        模块名称
      </p>
      <el-input :placeholder="listData.title" v-model="listData.title" maxlength="15" show-word-limit
        class="mb10 w200 ml20"></el-input>
    </div>
    <p class="fs8 mt10 mb10">
      优惠券设置
    </p>
    <div class="" v-for="(item,index) in listData.list" :key="index">
      <div class="bgc0 ml10 pt5 pr5 pl5 pl5 pb10 mb10">
        <p>优惠券名称：{{item.name}}</p>
        <div>
          <p v-if="item.couponType == 1">优惠券内容：满{{item.minAmount}}减{{item.couponFaceValue}}元</p>
          <p v-else>满{{item.minAmount}}打{{item.couponDiscount}}折</p>
          <div class="d-flex mb10">
            <p>优惠券时间：{{item.grantStartTime}}至{{item.grantEndTime}}</p>
          </div>
          
        </div>
        <div class="flex-a-b-c ml10 mt20 mb10">
          <el-button type="primary" @click="choice_tap(index)">选择优惠券</el-button>
        </div>

      </div>

    </div>
    <el-dialog title="选择优惠券" width="70%" :center="true" :modal-append-to-body="false"
      :visible="dialogTableVisible" @close="dialogClose">
      <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange" v-if="memberData.length > 0" :total="total">
        <template v-slot:table>
          <el-table-column align="center" width="100" label="选择优惠券">
            <template slot-scope="scope">
              <el-radio :label="scope.row.couponId" v-model="selectGoodsId"
                @change="getTemplateRow(scope.$index,scope.row)"></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="优惠券名称" />
          <el-table-column align="center" label="优惠券内容" show-overflow-tooltip >
            <template slot-scope="scope">
              <p v-if="scope.row.couponType == 1">满{{scope.row.minAmount}}减{{scope.row.couponFaceValue}}元</p>
              <p v-else>满{{scope.row.minAmount}}打{{scope.row.couponDiscount}}折</p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" width="210px" align="center" label="优惠券活动时间">
            <template slot-scope="scope">
              {{ scope.row.grantStartTime  }} - {{scope.row.grantEndTime}}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <div class="flex-c-c w100P flex-column pt20 pb10" v-else-if="memberData.length == 0">
        <p class="mb20">暂无优惠券，需要设置优惠券</p>
        <el-button type="primary" plain @click="setGoods">
          去设置
        </el-button>
      </div>
      <div class="mt40 flex-c-c">
        <el-button type="primary" plain @click="cancel">
          取消
        </el-button>
        <el-button type="primary" @click="onConfirmShop">
          确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

  import {
    getGoodsInfoPCShop,
  } from "@/api/miniProgram";
  import { crmPcPageList, } from "@/api/coupon";
  import { getDataTimeSec } from "@/utils";
  import commonTable from "@/components/common/commonTable";
  export default {
    props: ['listData', , 'showTips'],
    data() {
      return {
        currentIndex: '',
        currentPage: 1,
        pageSize: '',
        memberData: '',
        total: '',
        dialogTableVisible: false,
        loading: false,
        selectGoodsId: '',
        currentGoods: ''
      };
    },
    components: {
      commonTable
    },
    created() {
      this.queryShop()
    },
    filters: {
      getDataTimeSec(val) {
        return getDataTimeSec(val, 'yy-mm-dd');
      }
    },
    methods: {
      queryShop() {
        let data = {
          pageNum: this.currentPage
        }
        this.loading = true
        let than = this
        crmPcPageList(data)
          .then(res => {
            
            if (res.data) {
              this.loading = false;
              this.memberData = res.data.list || []
              this.total =  res.data.total
            } else {
              this.memberData = []
            }
          })
          .catch(err => {
            this.loading = false;
          })
      },
      choice_tap(index) {
        this.queryShop()
        this.currentIndex = index
        this.selectGoodsId = this.listData.list[index].couponId ? this.listData.list[index].couponId : ''
        this.dialogTableVisible = true
      },
      handleSizeChange(val) {
        this.pageSize = val;
      },
      handleCurrentChange(val) {
        
        this.currentPage = val;
        this.$emit('dialogCurrentChange', val);
      },
      dialogClose() {
        this.dialogTableVisible = false
      },
      getTemplateRow(index, row) {
        this.currentGoods = row
      },
      onConfirmShop() {
        let type = this.listData.type
        let than = this
        let list = than.listData.list
        for (var i in list) {
          if (list[i].couponId == than.currentGoods.couponId) {
            this.dialogTableVisible = false
            return than.$message.error('该优惠卷已经选择，请选择其他优惠卷')
          }
        }
        than.$emit('confirmSeckill', than.currentIndex, than.currentGoods, type)
        this.dialogTableVisible = false
        than.currentGoods = ''

      },
      //取消
      cancel() {
        this.dialogTableVisible = false
      },
      //设置商品
      setGoods() {
        this.$router.push('/couponList')
      },
    }
  };
</script>

<style lang="scss" scoped>

</style>